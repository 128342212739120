import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import FrontPage from "../FrontPage";
import PersonPage from "../PersonPage";

const App = () => {
  return (
    <Router>
      <div className="app">
        <div className="page-container">
          <Header />

          <Route path="/" exact component={FrontPage} />
          <Route path="/:name" component={PersonPage} />

          <Footer />
        </div>
      </div>
    </Router>
  );
};

export default App;
