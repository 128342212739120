export const currentYear = "2020";

export const ContentType = {
  TEXT: "text",
  LIST: "list",
};

const content = {
  2020: {
    jens: {
      name: "Jens",
      profilePicture: require("../assets/images/2020/jens/1.JPG").default,
      content: [
        {
          type: ContentType.TEXT,
          value: [
            "Siste lørdag før jul er kanskje den dagen folk kaver mest. Desto bedre grunn å sette seg ned, sette på litt julemusikk i bakgrunnen (Sondre Bratland’s mystiske «Rosa frå Betlehem») og samle tankene for en 2020-oppsummering med julehilsen.",

            "Ja, jeg skulle gjerne unnvært de mange måneder i underetasjen på hjemmekontor. Ja, jeg skulle gjerne ønsket å reise mer. Og ikke minst: Ja, jeg skulle så gjerne ønsket og hilst og klemt på nær familie og gode venner. Men bortsett fra det har jeg, sammen med de rundt meg, holdt oss friske, hatt et godt år og er takknemlige for det.",

            "En av grunnene til at hjemmekontor går greit er at hovedoppgaven selskapet har gitt meg med å lede utvikling av kunstig intelligens løsninger for seismiske tolkere, svært spennende. Neste år blir en milepæl da fruktene av det fem år lange prosjektet skal tas i bruk.",

            "Ellers er familieliv med nærhet til guttene, svigerdøtre og barnebarn lyspunkter gjennom ukene. Monika og jeg priser oss lykkelige med å ha de innenfor 30 min, så vi får fulgt opp både Adam (snart 3) og Luna (1 ½). Og i neste måned kommer Annie. Vi fryder oss storlig!",

            "Selv om det var veeeeeldig mye fram og tilbake, fikk vi til en storfamiliereise til Nord-Jylland. Det gjorde godt for sjel og sinn med en slik tur. Men sommerens høydepunkt var nok en langhelg hvor Monika og jeg rakk vandring på Nigardsbreen, klatring i Via Ferrata - Loen og omvisning på Astrup-tunet. Alt i praktfullt vær som på ypperste vis viste oss hvilken praktfull natur vi har i dette landet. Jeg må tilstå at klatringen i Via Ferrata (ca 1000 høydemeter) var noe utenfor min komfortgrense. Det gjorde det ikke særlig bedre at snittalder på resten av følget var mellom 20 og 25. Men for en opplevelse! Kan anbefales til alle som ikke har høydeskrekk 😊",

            "I dag uler vinden, regnet kommer ned i byger, temperert til 11 varme grader. Kanskje en typisk bergensk jul, men mine forhåpninger ligger noe høyere. Derfor gjør vi som tidligere år: stikker opp til Myrkdalen med drøm om den hvite jul (ref. Bing Crosby). Meldingene på værappene (som studeres flittig) er i hvert fall positive. En jul med nær familie blir en god avslutning på det som for alle har vært et rart og til tider tungt år. Så er våre og alles håp for neste år der. Jeg ønsker dere alle en riktig god jul og det aller beste for 2021.",

            "En god klem,",
          ],
        },
      ],
      gallery: [
        {
          original: require("../assets/images/2020/jens/2.jpg").default,
          description: "Adam og jeg utforsker stranden i Danmark",
        },
        {
          original: require("../assets/images/2020/jens/3.jpg").default,
          description: "Monika og meg ved Nigardsbreen",
        },
        {
          original: require("../assets/images/2020/jens/4.jpg").default,
          description: "Nasjonalromantikk, Astruptunet ved Jølstervannet",
        },
        {
          original: require("../assets/images/2020/jens/IMG_3699.JPG").default,
          description: "Kveldsstund med Luna",
        },
        {
          original: require("../assets/images/2020/jens/IMG_5747.jpg").default,
          description: "Herlig golfdag på Fana",
        },
        {
          original: require("../assets/images/2020/jens/teams-jens.jpg").default,
          description:
            "Med de mange teams-møtene som fyller dagen må man være litt kreativ med bakgrunn",
        },
      ],
    },
    monika: {
      name: "Monika",
      profilePicture: require("../assets/images/2020/monika/1.JPG").default,
      profilePictureStyles: {
        objectPosition: "90%",
      },
      content: [
        {
          type: ContentType.TEXT,
          value: [
            "Et spesielt år for oss alle med mindre sosial kontakt enn vanlig.",

            "Jeg savner klemmene. Men jeg har vært av de heldige som har hatt en trygg jobb, og hver dag kunne møte både pasienter og mine gode kollegaer. Arbeidshverdagen har vært annerledes og spesiell, men vi har funnet gode rutiner og arbeidsmåter som fungerer.",

            "Ellers er de skjønne barnebarna min viktigste hobby! De er så herlige å være sammen med. Gøy å få følge utvikling og se deres personligheter. Det er godt å se de er trygge, glade og trives. Vi setter stor pris på at de alle bor i Bergen/Askøy slik at vi ofte kan møte barnebarn, guttene våre og deres flotte Elisabeth’er.",

            "Vi fikk en hyggelig ferieuke alle sammen i sommer. Sommerhus rett ved en nydelig strand som var perfekt som «stor sandkasse» for barna. I tillegg en stor hage med mange spill og aktiviteter. Innendørs badebasseng var det også, og barna koste seg og ble trygge i vannet.",

            "Senere på sommeren fikk Jens og jeg anledning til aktivitetsferie. Først vandring på isbre. Utrolig følelse og stirre ned i dype sprekker og tenke på hvor mange år isen har ligget. Så til klatring i via Ferrata i Loen. Det var en fantastisk følelse, mange høydemetere ned, sug i magen, balansere over et juv kun på en line, spektakulær utsikt. Kjempe moro. Litt sprelsk må man være, og gøy og utfordre seg litt.",

            "Nå blir det godt med juleferie sammen med vår familie-kohort.",

            "Riktig god jul til deg",
          ],
        },
      ],
      gallery: [
        {
          original: require("../assets/images/2020/monika/2.jpg").default,
        },
        {
          original: require("../assets/images/2020/monika/3.jpg").default,
        },
        {
          original: require("../assets/images/2020/monika/4.JPG").default,
        },
      ],
    },
    "elisabeth-christian-og-adam": {
      name: "Elisabeth, Christian & Adam",
      profilePicture: require("../assets/images/2020/elisabeth-christian-og-adam/IMG_2099.JPG")
        .default,
      profilePictureStyles: {
        objectPosition: "45%",
      },
      content: [
        {
          type: ContentType.TEXT,
          value: [
            "Man skulle trodd at 2020 ble et lite begivenhetsrikt år, men for familien Arnesen Grimsgaard var det helt det motsatte.",

            "I februar fikk Elisabeth og Christian endelig muligheten til å reise på bryllupsreise. Ferden gikk til Paris hvor vi fant det koseligste hotellet med den koseligste bartenderen som ville lage personlige drinker til oss hver kveld. Det ville bare være uhøflig å takke nei.",

            "Uten at det har noen videre sammenheng, fant vi ut i mars at vi skal bli foreldre til en liten jente. Okei, kanskje litt mer planlagt denne gangen. Hun skal hete Annie og er planlagt skal komme tidlig på nyåret. Vi kunne ikke vært mer spent!",

            "Med en voksende familie så vi behovet for et større hus. Med familie på både Nordås og Sotra, falt vi for en gyllen middelvei på Askøy. Leiligheten ble lagt ut til salgs samme dag som nye retningslinjer for fellesvisning, og det viste seg å være et seigt marked. Det ble en vår med mange privatvisninger uten mye hell.",

            "Sommeren kom og det ble heldigvis åpnet opp grensen for Danmarksferie med både familien Grimsgaard og familien Arnesen (etterfulgt av hverandre, ikke samtidig).",

            "Etter sommeren begynte Adam på stor avdeling i barnehagen, noe han ikke var så fornøyd med. Det ble vanskeligere og vanskeligere å tenke på en trist gutt som ikke trivdes på den nye avdelingen, og ettersom vi skulle overta nytt hus tidlig september, fikk vi fremskyndet overgangen til ny barnehage. Og for en god overgang! Adam elsker den nye barnehagen. Den ser ut som en fornøyelsespark, men det er ikke uten gråt der heller. Han vil nemlig ikke dra hjem når han blir hentet. Det er i det minste et godt tegn.",

            "Den andre uken i september var gøyal. Leiligheten ble endelig solgt tirsdag og vi fikk overta det nye huset onsdag. Og som om ikke det var nok takket Christian ja til ny jobb på fredag, midt i flyttelasset. Christian begynner hos Kantega som senior frontendutvikler. Stillingstittelen gir dessverre ikke seniorrabatt på bussen.",

            'En litt for tidlig morgen mens pappa forberedte sin første kaffekopp, skulle Adam bare lukte på en kaffebønne. Den noe uoppmerksomme pappaen fikk da ikke med seg at Adam stappet hele bønnen langt opp i nesa og sniffet hardt til. Der satt den godt fast. Halv ni ble mamma vekket med "Duuuuuu... Vi må gjøre oss klar til å dra på legevakten for Adam har en kaffebønne i nesa." Den komiske starten på morgenen ble til et langt, kjipt og kjedelig eventyr på legevakten og Haukeland der Adam til slutt måtte i narkose for å få ut bønnen. Pappa skal følge litt bedre med neste gang han veier kaffe ved frokostbordet.',

            "Det har tatt tid å komme inn i nytt hus, men takket være hjelpsom familie på begge sider har det gått mye raskere enn vi kunne håpet på. Elisabeth har vært en malemester uten like og barnerommene er blitt malt i gøye farger og med spennende motiver, og nå gjør vi alt vi kan for at redebyggingen skal være ferdigstilt til vår lille Annie kommer til verden.",

            "Julen skal feires på Sotra, men i stedet for å feire romjulen i Myrkdalen som vi pleier, har vi valgt å holde oss litt nærmere fødestua bare sånn i tilfelle.",

            "Vi ønsker alle en riktig god jul og et godt nyttår.",
          ],
        },
      ],
      gallery: [
        {
          original: require("../assets/images/2020/elisabeth-christian-og-adam/IMG_1535.JPG")
            .default,
          description: "Lykkelig gift i 8 måneder",
        },
        {
          original: require("../assets/images/2020/elisabeth-christian-og-adam/IMG_1516.JPG")
            .default,
          description:
            "Passer ikke helt inn på Michelin-stjerneresturant, men vi later som",
        },
        {
          original: require("../assets/images/2020/elisabeth-christian-og-adam/IMG_1581.PNG")
            .default,
          description:
            "Når vi ikke får lov å møte hverandre fysisk må vi gjøre det digitalt",
        },
        {
          original: require("../assets/images/2020/elisabeth-christian-og-adam/IMG_1791.JPG")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-christian-og-adam/IMG_1857.JPG")
            .default,
          description: "Bekkepa sin bursdagspresang",
        },
        {
          original: require("../assets/images/2020/elisabeth-christian-og-adam/IMG_0037.jpg")
            .default,
          description:
            "Det var et par flasker med denne etiketten også. Reaksjonen var herlig!",
        },
        {
          original: require("../assets/images/2020/elisabeth-christian-og-adam/IMG_1965.jpg")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-christian-og-adam/IMG_1966.JPG")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-christian-og-adam/IMG_1968.JPG")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-christian-og-adam/IMG_2049.jpg")
            .default,
          description:
            "Bestefar, bestemor og Adam på vei til stranda i Danmark",
        },
        {
          original: require("../assets/images/2020/elisabeth-christian-og-adam/IMG_2158.JPG")
            .default,
          description: "Glad barnehagegutt i ny barnehage",
        },

        {
          original: require("../assets/images/2020/elisabeth-christian-og-adam/IMG_1290.JPG")
            .default,
          description:
            "Adam klarte å sette fast en kaffebønne opp i nesa i et øyeblikk pappa ikke fulgte helt med...",
        },
      ],
    },
    "elisabeth-oyvind-og-luna": {
      name: "Elisabeth, Øyvind & Luna",
      profilePicture: require("../assets/images/2020/elisabeth-oyvind-og-luna/132045599_466846691437415_9201376729249084858_n.jpg")
        .default,
      profilePictureStyles: {
        objectPosition: "35%",
      },
      content: [
        {
          type: ContentType.TEXT,
          value: [
            "Et spesielt år er nå lagt bak oss, og julestemningen faller endelig til ro. Man kan si mye om dette året, men det som det har betydd i praksis for oss, er mer tid med hverandre i vår lille familie. Og da er det godt at vi tre har en så fin hverdag sammen. Luna vokser opp og vi følger hver lille fremgang med stor glede. Man skulle ikke tro det går an å bli mer forelsket i henne for hver dag som går, men det gjør det utrolig nok.",

            "I mars tok Øyvind over stafettpinnen fra Elisabeth, og begynte sin pappapermisjon. Ønske om aktiv permisjon ute med jevnlige besøk i åpen barnehage gikk logisk nok ikke, men tiden sammen har vært veldig fin.",

            "I juni skulle vi på vår første familieferie sammen til Nice, men den ble dessverre kansellert. Heldigvis fikk vi likevel oppleve mye, og ferien tok sted til Møkster, Sandefjord og Arendal hvor Luna fikk hilse på oldeforeldrene sine, og Danmarkstur med hele familien Grimsgaard.",

            "Så kom høsten, og Luna begynte i barnehage. Det var en merkelig følelse for oss, så da var det ekstra fint å se at hun trives så godt. Nå har hun fått seg en bestevenn, Awa, som hun leker med hele tiden. Høsten begynte Luna også å gå, og har blitt en svært aktiv jente. Hun elsker å leke og det er alltid mye liv med henne. Alltid.",

            "I oktober begynte Øyvind i ny jobb som forretningsutvikler i Youwell. Dette har vært veldig spennende og han trives svært godt. Elisabeth har jobbet i år som psykomotorisk fysioterapeut i egen praksis på Skjold Helsehus, og gjennomførte også sin siste eksamen i desember til svært gode tilbakemeldinger. Nå er det kun masteroppgaven igjen, som skal leveres i juni (men hun er nesten ferdig med den).",

            "Med det ønsker vi dere alle en riktig god jul og godt nyttår.",
          ],
        },
      ],
      gallery: [
        {
          original: require("../assets/images/2020/elisabeth-oyvind-og-luna/131995461_444019223462439_1286989546799494927_n.jpg")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-oyvind-og-luna/132007456_150319209865007_2551240558218650741_n.jpg")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-oyvind-og-luna/132009554_853690182085959_2349258312351742529_n.jpg")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-oyvind-og-luna/132016773_217873253081413_1007351590026612244_n.jpg")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-oyvind-og-luna/132036128_897827944088302_4547396913819177905_n.jpg")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-oyvind-og-luna/132039681_797217821003051_3852688437575950440_n.jpg")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-oyvind-og-luna/132041237_321594068929715_272580533088292358_n.jpg")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-oyvind-og-luna/132042004_900134077396592_1361356072297457159_n.jpg")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-oyvind-og-luna/132043945_1081021239048592_686347694524149166_n.jpg")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-oyvind-og-luna/132048113_823010018259430_3992483133524571157_n.jpg")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-oyvind-og-luna/132050489_2752296628326945_714763355050896255_n.jpg")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-oyvind-og-luna/132068967_230688068424384_1667532190582190439_n.jpg")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-oyvind-og-luna/132089728_1031910864255779_4909379516583769270_n.jpg")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-oyvind-og-luna/132100066_236884421123068_7716908094536328432_n.jpg")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-oyvind-og-luna/132122474_411805010169680_3785669703377696857_n.jpg")
            .default,
        },
        {
          original: require("../assets/images/2020/elisabeth-oyvind-og-luna/132124982_408631463711168_4291026378939747999_n.jpg")
            .default,
        },
      ],
    },
  },
  2019: {
    jens: {
      name: "Jens",
      profilePicture: require("../assets/images/2019/jens/1.jpg"),
      content: [
        {
          type: ContentType.TEXT,
          value: [
            "Skal 2019 rammes inn må det bli med en lys, positiv, glad og fargerik ramme. Og stor må den være for å kunne romme alt året har bydd på. Det er ikke noen stor overdrivelse å si at de kjærlige og stolte følelsene var i høysete med lille Luna sin ankomst i slutten av juli og med Elisabeth og Christian sitt flotte bryllup i slutten av juni. Dagene og ukene passerer i ekspressfart, men det er muligens fordi aktivitetsnivået er høyt og opplevelsene kommer tett.",
            "Hytta i Myrkdalen er og vil fremdeles bli et yndet mål for fritidsaktivitet og rekreasjon gjennom vintertiden. Med økning av familiemedlemmer var det bare å innse at uteboden for ski, akebrett og pulker hadde nådd et kapasitetsproblem. Påbygg på hytta er nå gjort og vi håper den er nå dimensjonert for å romme den nye generasjonens vinterlige utstyr og interesser.",
            "Når snøen er smeltet og soldagene blir lengre er det ikke til å komme fra at sydligere strøk lokker. En deilig sommerferie i Spania med de nygifte og vesle Adam, samt en seinhøst-ferie til Mauritius har gitt oss det nødvendige påfyll av D-vitaminer for en stakkars (kvasi)bergenser.",
            "I fritiden går det fremdeles i bowling og golf. Heldigvis er sesongene godt fordelt over året. Bowlingen er blitt muligens noe mer seriøst med aktiv 1.divisjonsspill med Bergen Bowling Club.",
            "I september kunne jeg markere 35 år i samme selskap! Det som er fantastisk er at arbeidsdagen er like spennende som da jeg fikk mine første oppgaver i Hydro i Utforskning Bergen. Teknologien gjør store byks og nå er fokus på hvordan man utnytter Deep Learning (kunstig intelligens) til å tolke seismiske data som en erfaren tolker hadde gjort. Og det er faktisk mulig!",
            "Nå er det ikke mange dager før bilen pakkes og vi setter kurs for julefeiring på hytta. En velsignet god (og forhåpentligvis hvit) jul ønskes dere alle, samt det beste for 2020.",
            "En god juleklem fra Jens",
          ],
        },
      ],
      gallery: [
        {
          original: require("../assets/images/2019/jens/2.jpg"),
          description:
            "Ny æra med pulketrekk har startet. Adam var godt fornøyd (dvs sov)",
        },
        {
          original: require("../assets/images/2019/jens/3.jpg"),
          description:
            "Tre generasjoner på Velkommen-til-verden-fest: smilende Luna, stolt pappa Øyvind og Bestefar",
        },
        {
          original: require("../assets/images/2019/jens/4.jpg"),
          description: "Monika og jeg etter vårt første Mauritius-dykk.",
        },
        {
          original: require("../assets/images/2019/jens/5.jpg"),
          description: "En av de bedre lunsjstedene.",
        },
        {
          original: require("../assets/images/2019/jens/6.jpg"),
          description: "En sliten krabat.",
        },
      ],
    },
    monika: {
      name: "Monika",
      profilePicture: require("../assets/images/2019/monika/4.jpg"),
      content: [
        {
          type: ContentType.TEXT,
          value: [
            "Når noen spør meg hvordan jeg har det, så svarer jeg at jeg har det fantastisk for jeg er jo bestemor! Ja, jeg er så heldig å være bestemor til de to skjønneste barna som finnes😍 Begge våre gutter med sine flotte Elisabeth’er og barn, bor i Bergen. Det betyr at vi får følge disse småtassene i sin spennende utvikling. Det varmer når Luna på 4 måneder smiler til meg og en hånd som griper. Det er nydelig å få hente Adam, på snart to år, i barnehagen og få en klem, bli vist hvilke bilder han har malt, få ta han med hjem og være med i lek.",

            "I fjor giftet Elisabeth og Øyvind seg, og Christian og Elisabeth fikk Adam. I år giftet Christian og Elisabeth seg, og Øyvind og Elisabeth fikk Luna. Jeg kan klare å venne meg til slikt; ikke flere bryllup, men årlig barnebarn...😊 Vi har hatt 2 år med mye lykke og glede.",

            "Ellers nyter vi fremdeles hytten vår i fulle drag, mest på vinterstid. Nå gleder vi oss til ny skisesong. Det gir avslapping og hygge, og jeg mener bestemt det gir næring til sjelen. Skuldrene er aldri så lave som etter en skitur på Vikafjell og sittende foran peisen om kvelden eller rundt bålpannen. Rommene måtte omgjøres litt slik at det er mer praktisk for barnefamilier, og hytta måtte bygges på med mer bodplass for flere ski, akebrett og pulk. Så nå er alt lagt til rette for mange koselige helgeturer som storfamilie.",

            "Vi liker jo sol og sommer også, så Jens og jeg fant ut at vi bare MÅTTE feire Jens sin 60 års dag med å reise til Mauritius. Og det var ikke noe dumt valg. Sol, hvite strender, masse snorkling med flotte fisker og koraller, dykking (med hai og skilpadder), paddleboard, golf og selvfølgelig god mat og is. Vi fikk forsøkt oss på matlagingskurs med chef’en, lærte segadans, var med og feiret en av hinduenes største helligdag, divali, og fikk litt innsikt i kulturen. ",
            "Sol, varme og bading fikk vi også i sommer sammen med Christian, Elisabeth og Adam i Spania. Gøy å se hvordan Adam koste seg med bading og sandstrand. Og jeg nøt å ha mange dager sammen med dem alle.",

            "Julen skal som vanlig feires på fjellet i hvite omgivelser.",
            "Jeg ønsker deg en riktig god jul og godt nyttår!",
          ],
        },
      ],
      gallery: [
        {
          original: require("../assets/images/2019/monika/1.jpg"),
        },
        {
          original: require("../assets/images/2019/monika/2.JPG"),
        },
        {
          original: require("../assets/images/2019/monika/3.JPG"),
        },
      ],
    },
    "elisabeth-christian-og-adam": {
      name: "Elisabeth, Christian & Adam",
      profilePicture: require("../assets/images/2019/elisabeth-christian-og-adam/1.jpg"),
      content: [
        {
          type: ContentType.TEXT,
          value: [
            "Året 2019 har flydd forbi i en fart. Et begivelsesrikt år på kvalitet og kvantitet.",
            "Vi begynte året som nyforlovet og bega oss inn en verden av planlegging og logistikk. Det er vanskelig å få alt riktig når man ikke vet hvor man skal begynne, men vi fant mange nyttige artikler og hadde mange å prate med om dette. Elisabeth fant en veldig kjekk liste over ting man bør få på plass før bryllupet og i hvor god tid. Bare dumt at dette var en uke før bryllupet. Tydeligvis var noen av tingene vi hadde igjen noe som burde vært gjort for måneder siden. Jaja.",
            "Dagen før valentines day ble vi fotografert og intervjuet til BA fordi vi er så ufattelig søte og har en så romantisk og moderne historie. Noe som forsåvidt er sant. For de som vil lese artikkelen er det bare å sende oss en melding (siden den egentlig kun er for BA+).",
            "Vi feiret Adam sin første bursdag! Med foreldre, besteforeldre, tanter, onkler og fetter til stede hadde Adam det kjempekjekt. En helt egen kake fikk han også ødelegge.",
            "Påska feiret vi på hytta med bestemor og bestefar Grimsgaard. Adam vant sitt første skirenn.",
            "Fra påska føltes det ikke som mange ukene gikk før vi sto ved alteret. For en fantastisk dag! Det føles litt uvirkelig at så mange av våre nærmeste og kjære kom både kortreist og langreist fra, kun for å feire kjærlighetsdagen vår. Alle de månedene med planlegging og stress ble alt glemt når vi sitter igjen med opplevelsen, minnene og alle de nydelige ordene fra talene. Til alle som deltok i bryllupet vårt, tusen takk!",
            "Bryllupsreisen gikk til Spania med vårt gode reisefølge: bestemor og bestefar Grimsgaard. Det likte Adam veldig godt. Vi også, altså. Der fikk vi tid til å roe ned, lande litt og få litt farge.",
            "Av annet nytt som ikke fortjener en hel paragraf hver, har vi hatt masse sykdom siden Adam har begynt i barnehage, mange turer til hytta, Christian har blitt litt for kaffeinteressert og skaffet seg skjegg og Elisabeth har gjennomført et halvår med 100% jobb, 100% studie samtidig som hun er 100% mamma.",
            "2020 virker allerede som det skal bli ganske begivelsesrikt det også, men skal ikke spoile noe av neste års julebrev.",
            "God jul til alle sammen!",
          ],
        },
      ],
      gallery: [
        {
          original: require("../assets/images/2019/elisabeth-christian-og-adam/2.jpg"),
          description: "Foto: Emil Weatherhead Breistein",
        },
        {
          original: require("../assets/images/2019/elisabeth-christian-og-adam/3.JPG"),
          description: "Kjærlighet og romantikk",
        },
        {
          original: require("../assets/images/2019/elisabeth-christian-og-adam/4.jpg"),
          description: "Adam på 1 årsdagen",
        },
        {
          original: require("../assets/images/2019/elisabeth-christian-og-adam/5.jpg"),
          description: "Vant 1. plass i skirenn",
        },
        {
          original: require("../assets/images/2019/elisabeth-christian-og-adam/6.JPG"),
        },
        {
          original: require("../assets/images/2019/elisabeth-christian-og-adam/7.jpg"),
          description: "17. mai-feiring",
        },
        {
          original: require("../assets/images/2019/elisabeth-christian-og-adam/8.JPG"),
          description: "Egenbrygget øl og cider til bryllupet",
        },
        {
          original: require("../assets/images/2019/elisabeth-christian-og-adam/9.jpg"),
          description: "Foto: Åshild Meling",
        },
        {
          original: require("../assets/images/2019/elisabeth-christian-og-adam/10.JPG"),
          description: "På tur til Gibraltar",
        },
        {
          original: require("../assets/images/2019/elisabeth-christian-og-adam/11.JPG"),
          description: "Adam liker godt å sitte på ryggen til pappa...",
        },
        {
          original: require("../assets/images/2019/elisabeth-christian-og-adam/12.JPG"),
          description: "...og på hoften til mamma.",
        },
      ],
    },
    "elisabeth-oyvind-og-luna": {
      name: "Elisabeth, Øyvind & Luna",
      profilePicture: require("../assets/images/2019/elisabeth-oyvind-og-luna/1.JPG"),
      content: [
        {
          type: ContentType.TEXT,
          value: [
            "2019 har vært et helt spesielt år for oss, siden vår lille familie har gått fra to til tre. Dette har uten tvil vært årets høydepunkt, og trolig livets høydepunkt. Lille Luna ble født 30. juli kl 09.27 og med sine 3080 gram og 49 cm var hun den vakreste skapningen vi noensinne hadde sett.",
            "Elisabeth hadde en veldig fin graviditet, og koste seg med å oppleve at magen vokste og å bli kjent med den lille mageboeren. Heldigvis var formen fin hele veien og vi nøt en varm og solrik sommer i Bergen med mye bading før fødselen. En hyggelig babyshower ble også holdt for Luna dagen før hun ble født.",
            "Tøffe søvnløse netter har heldigvis glidd over i en god rytme, og vi har det så fint i hverdagen med vår lille vidunder. Det er ingen tvil om at dette er en stor omveltning i livet vårt, og vi har definitivt fått en ny sjef i huset som bestemmer mat, søvn og sosiale aktiviteter, men Luna har fylt våre hjerter med mer kjærlighet enn vi trodde var mulig. ",
            "I november hadde vi gleden av å invitere til en ”Velkommen til verden”-fest hvor det var så hyggelig å introdusere Luna for familie og venner. Høydepunktet var da bestefar og onkel Christian framførte ”Ein farfar i livet” av Odd Nordstoga.",
            "Ellers har året vært begivenhetsrikt. I juli fikk Øyvind æren av å være forlover til Christian, da Christian og Elisabeth giftet seg i Skjold Kirke. Elisabeth og Øyvind storkoste seg med å framføre et lite musikalsk bidrag til vielsen. Øyvind koser seg også i sin faste jobb som manager og har blant annet vært på en turné i Europa og Asia i år. Elisabeth fortsetter på sin master i psykomotorisk fysioterapi og trives svært godt. ",
            "Nå har hele familien skaffet julegensere, og vi koser oss masse i adventstiden. Og med det så ønsker vi en god jul og et godt nyttår til alle <3 ",
          ],
        },
      ],
      gallery: [
        {
          original: require("../assets/images/2019/elisabeth-oyvind-og-luna/2.JPG"),
        },
        {
          original: require("../assets/images/2019/elisabeth-oyvind-og-luna/3.JPG"),
        },
        {
          original: require("../assets/images/2019/elisabeth-oyvind-og-luna/4.JPG"),
        },
        {
          original: require("../assets/images/2019/elisabeth-oyvind-og-luna/5.JPG"),
        },
      ],
    },
  },
  2018: {
    "elisabeth-og-oyvind": {
      name: "Elisabeth & Øyvind",
      profilePicture: require("../assets/images/2018/elisabeth-og-oyvind/1.jpg"),
      content: [
        {
          type: ContentType.TEXT,
          value: [
            "2018 begynte bokstavelig talt med et pang. Øyvind gikk ned på kne under en stjernehimmel fylt av fyrverkeri, og spurte Elisabeth om å tilbringe resten av livet sammen. Omringet av glede og håp for et nytt år svarte hun ja – selvfølgelig!",
            "Innimellom planleggingen av bryllupet som skulle finne sted siste helg i september, i det vakre toskanske landskapet, har året bydd på mye. Elisabeth fullførte fysioterapiutdanningen sin i august, og begynte rett på mastergrad i psykomotorisk fysioterapi. Der trives hun veldig godt. Øyvind tilbragte 10 uker på Gründerskolen i Toronto i sommer som var svært lærerikt og fylt med mange gode opplevelser. Høydepunktet var nok likevel da Elisabeth besøkte han, og sammen dro vi på festival og helgetur til Niagara Falls.",
            "Så kom endelig høsten, og vi var så spente på å dra til Italia som skulle vise seg å bli vårt absolutt drømmebryllup. Villa Barberino var et nydelig hotell som kanskje mer minnet om en liten landsby der alle våre venner og familie bodde for en helg. I nydelige grønne og varme omgivelser fikk vi oppleve vinsmaking, pizzakveld, utendørs seremoni og en fantastisk bryllupsmiddag – alt sammen med de beste menneskene. Vi kommer aldri til å glemme denne helgen, og vil gi en ekstra varm takk til alle menneskene som gjorde den så spesiell. Spesielt vil Øyvind aldri glemme da Elisabeth overrasket han med å synge en klassisk sang under middagen. Selv om vi synes dette var verdens beste dag, så er nå alle dager best siden vi tilbringer livet vårt sammen.",
            "Nå gleder vi oss veldig til hva framtiden har å by på. Øyvind har levert masteroppgaven sin og er offisielt en siviløkonom. Til januar skal han begynne i drømmejobben sin som manager for artister, som han gleder seg veldig til.",
            "Og med det så ønsker vi en god jul og et godt nyttår til alle <3",
          ],
        },
      ],
      gallery: [
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/2.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/3.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/4.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/5.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/6.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/7.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/8.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/9.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/10.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/11.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/12.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/13.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/14.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/15.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/16.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/17.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/18.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/19.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/20.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/21.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-og-oyvind/22.jpg"),
        },
      ],
    },
    "elisabeth-christian-og-adam": {
      name: "Elisabeth, Christian & Adam",
      profilePicture: require("../assets/images/2018/elisabeth-christian-og-adam/1.jpg"),
      content: [
        {
          type: ContentType.TEXT,
          value: [
            "Ja, for et år! Frem til midten av februar gikk tiden til å forberede oss på det som skulle komme, og 10 dager før termin poppet Adam ut for å hilse på verden. Livet som nybakte foreldre var krevende og fantastisk. Det blir en klisjé, men man oppdager en kjærlighet man aldri før har kjent. Med enda flere besøk til og fra Adams besteforeldrene enn før har vi fått mange gode stunder og masse hjelp.",
            "De første månedene viste seg å bli harde for Adam med hyppige sykehusbesøk. Høy feber, ørebetennelse, slimete luftveier og astmatiske tendenser har alle bidratt til at vi har blitt godt kjent med Haukeland sin barneavdeling, som heldigvis tar godt vare på sine pasienter.",
            "I sommerferien fikk vi testet ut ladenettverket til Tesla som hjalp oss på besøk til Adams oldeforeldre i Sandefjord og Elisabeths tante i Sverige. Det var Christians første tur til Sverige og blir langt i fra hans siste!",
            "Elisabeths far, Atle, fylte 60 år og inviterte storfamilien på tur til Kroatia. Der tilbrakte vi en herlig uke i nærmere 30 varmegrader med mye bading.",
            "August og september ble travle måneder med planlegging av to utdrikningslag og deltakelse i medfølgende bryllup. Både Øyvind og Christians venn Erlend hadde spurt Christian om å være forlover, noe han hjertlig takket ja til.",
            "Med en ny foreldretilværelse og masse bryllup blomstret kjærligheten som aldri før. Og 7. desember – på toårsdagen til Christian og Elisabeth – gikk Christian ned på kne og fikk et klart «ja».",
          ],
        },
      ],
      gallery: [
        {
          original: require("../assets/images/2018/elisabeth-christian-og-adam/2.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-christian-og-adam/3.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-christian-og-adam/4.JPG"),
        },
        {
          original: require("../assets/images/2018/elisabeth-christian-og-adam/5.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-christian-og-adam/6.JPG"),
        },
        {
          original: require("../assets/images/2018/elisabeth-christian-og-adam/7.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-christian-og-adam/8.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-christian-og-adam/9.JPG"),
        },
        {
          original: require("../assets/images/2018/elisabeth-christian-og-adam/10.jpg"),
        },
        {
          original: require("../assets/images/2018/elisabeth-christian-og-adam/11.jpg"),
        },
      ],
    },
    "monika-og-jens": {
      name: "Monika og Jens",
      profilePicture: require("../assets/images/2018/monika-og-jens/1.png"),
      content: [
        {
          type: ContentType.TEXT,
          value: [
            "For et eventyrlig år 2018 har vært for oss og resten av familien! Høydepunktene har kommet som vakre perler på en lang snor. Det er ikke mulig å yte hver av de en fullgod skildring, men for å ta det fra begynnelse: våre 30 år i ekteskapspakten ble feiret på Maldivene i januar/februar med dykking, snorkling, bading, fisking, masse sol, og fantastisk mat. At Monika orket å ta dykkersertifikat i iskaldt desember-vann utenfor Laksevåg sier hvor mye det ble satset på denne ferien. At hun også klarte å holde roen med haier i sikte var enda mer imponerende. At denne ferieopplevelsen aspirerer til «Best summer vacation ever» hersker det ingen tvil om.",
            "I slutten av februar fikk vi så oppleve at Elisabeth og Christian ble foreldre til en ufattelig skjønn liten Adam. Å bli besteforeldre er som de sier: å bli forelsket igjen. Adam har fått lov til å fylle mange av våre timer og vi får bare ikke nok. At Elisabeth og Christian bare bor 10 min fra oss er en ren velsignelse – også for foreldre som får lett tilgang til overivrige barnevakter. I mars var det tid for å feire Jens sin mor i Arendal som var nådd den flotte alder av 85. En flott markering av en fremdeles meget oppegående dame!",
            "Sommerferien ble for første gang feiret uten guttene, naturlig nok ettersom Øyvind holdt seg i Toronto og Christian var nybakt pappa. Reisen gikk til Italias hæl hvor vi først hadde en liten uke som turist, primært på vestkysten av hælen, og deretter på en ukes sykkeltur med gode venner. Puglia var en del av Italia vi ikke tidligere hadde besøkt og vi kommer gjerne igjen. Det er ikke til å komme fra at Italia er og blir vårt ferieland nummer en.",
            "I august ble det feiring av Elisabeth og Øyvinds borgerlige ekteskapsinngåelse før vi i september dro ned til Toscana for å feire selve seremonien i fantastiske omgivelser - tre dager til ende. Under en varm og klar toskansk sol lovet brudeparet hverandre evig troskap og kjærlighet. At Jens fikk lov til å være seremonimester for dem, vil stå for ham som en meget sterk opplevelse. Etterpå hadde Christian, Monika og Jens en mini-ferie hvor Toscana ble utforsket enda mer med varme kilder, sverd i stein, gourmet opplevelser, vinsmaking og herlige landsbyer.",
            "I tillegg til dette fikk vi fylt helger og uker med:",
          ],
        },
        {
          type: ContentType.LIST,
          value: [
            "Hytta i Myrkdalen",
            "Helgetur til Trolltunga (Monika)",
            "Golfferie i Wales og Frankrike",
            "Besøk til Solveig og Oddbjørns hytte i Austbygdi med Rjukan og Gaustatoppen på listen",
            "Besøk til våre foreldre og søsken i Sandefjord og Arendal",
          ],
        },
        {
          type: ContentType.TEXT,
          value: [
            "Om noen savner link til Jens sin julevideo er det fordi den fikk restriksjoner i år. Universal (som eier sangen som ble brukt) var svært så negative da de hørte at originalteksten var endret. For å unngå søksmål har Equinor sine jurister anbefalt sterkt at den ikke spres. Synd, spesielt med tanke på at det er den aller siste som vil bli laget. Skulle det være noen som har et spesielt ønske om å få se den, kan dere ta kontakt med ham (jegri@equinor.com) og så skal vi se hva vi kan få til («nodge, nodge, say no more»).",
            "Og nå er det en snau uke til jul og tro det eller ei, det finnes ikke snev av jule-stress eller mas. Sikkert fordi vi er blitt glemsk.",
            "Vi ønsker dere alle en velsignet god jul og det beste for 2019!",
          ],
        },
      ],
      gallery: [
        {
          original: require("../assets/images/2018/monika-og-jens/2.jpg"),
          description: "Nærkontakt av tredje grad med rokke (marble ray)",
        },
        {
          original: require("../assets/images/2018/monika-og-jens/3.jpg"),
          description:
            "En sterk opplevelse når Monika møter sitt barnebarn, Adam, for første gang",
        },
        {
          original: require("../assets/images/2018/monika-og-jens/4.jpg"),
          description: "…og så vokser de så fort til!",
        },
        {
          original: require("../assets/images/2018/monika-og-jens/5.jpg"),
          description: "17.mai feiring med Jens i ny bunad",
        },
        {
          original: require("../assets/images/2018/monika-og-jens/6.jpg"),
          description:
            "Fantastiske Matera som er godt plassert på Unesco sin verdensarvliste",
        },
        {
          original: require("../assets/images/2018/monika-og-jens/7.jpg"),
          description:
            "Startpunkt på sykkelturen: Alberobello med sine spesielle hus. Og hvis noen synes Jens sin sykkel ligner på en el-sykkel så vil nok det bli benektet.",
        },
        {
          original: require("../assets/images/2018/monika-og-jens/8.jpg"),
          description:
            "Sykkelgjengen på muligens den varmeste dagen med mange-over-tretti grader",
        },
        {
          original: require("../assets/images/2018/monika-og-jens/9.jpg"),
          description:
            "Monika og Vigdis på toppen av Trolltunga i sludd, helt på tampen av sesongen",
        },
        {
          original: require("../assets/images/2018/monika-og-jens/10.jpg"),
          description:
            "En stolt far, brudgommen og hans to forlovere, Christian og Kristian.",
        },
        {
          original: require("../assets/images/2018/monika-og-jens/11.jpg"),
          description: "Brudepar med seremonimester",
        },
        {
          original: require("../assets/images/2018/monika-og-jens/12.jpg"),
          description:
            "Bryllupsfest under åpen himmel med utsikt over det vakre toskanske landskap",
        },
      ],
    },
  },
};

export const footer = [
  "Vi i familien Grimsgaard kjører som vanlig på med digitalt julekort. Christian får tulle videre med internett-ting og andre finurligheter.",
  "Hvert familiemedlem har sin side med en liten hilsen, så om du ikke allerede har gjort det ta gjerne turen innom disse menneskenes verden og hør hvordan året har vært.",
  "God jul fra familien Grimsgaard!",
];

export default content;
