import React from 'react';
import Section from '../Section';
import './Footer.scss';

import { footer as content } from '../../content';

const Footer = () => {
  return (
    <footer className='footer'>
      <Section>
        {content && content.map((p, i) =>
          <p className='footer__paragraph' key={i}>{p}</p>
        )}
      </Section>
    </footer>
  )
}

export default Footer;