import React from 'react';
import { Link } from 'react-router-dom';
import Section from '../Section';
import { currentYear } from '../../content';
import './Header.scss'

const Header = () => {
  return (
    <header className='header'>
      <Section>
        <div className='header__container'>
          <h2 className='header__title'>
            <Link className='header__link' to='/'>Familien Grimsgaard</Link>
            <small className='header__subtitle'>Julebrev {currentYear}</small>
          </h2>
        </div>
      </Section>
    </header>
  )
}

export default Header;
