import React from 'react';
import classnames from 'classnames';
import './Section.scss';

const Section = ({ children, isNarrow }) => {
  return (
    <section className={classnames('section', { 'section--narrow': isNarrow })}>
      {children}
    </section>
  )
}

export default Section;
