import React from 'react';
import Section from '../Section';
import './Signature.scss';

const Signature = ({ children }) => {
  return (
    <Section isNarrow>
      <div className='signature-container'>
        <span className='signature'>{children}</span>
      </div>
    </Section>
  )
}

export default Signature;
