import React from 'react';
import { Link } from 'react-router-dom';
import Section from '../Section';
import './Breadcrumbs.scss'

const Breadcrumbs = ({ activePageName }) => {
  return (
    <div className='breadcrumbs'>
      <Section>
        <nav className='breadcrumbs__nav' role='navigation'>
          <Link className='breadcrumbs__link' to='/'>Hjem</Link>
          <span className='breadcrumbs__link breadcrumbs__link--divider'>/</span>
          <span className='breadcrumbs__link breadcrumbs__link--active'>{activePageName}</span>
        </nav>
      </Section>
    </div>
  )
}

export default Breadcrumbs;
