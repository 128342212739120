import React from "react";
import { Link } from "react-router-dom";
import "./ProfilePicture.scss";

const ProfilePicture = ({ link, title, src, styles }) => {
  return (
    <Link to={link} className="profile-picture">
      <div className="profile-picture__overlay">
        <span className="profile-picture__name">{title}</span>
      </div>

      <img alt={title} title={title} src={src} style={styles} />
    </Link>
  );
};

export default ProfilePicture;
