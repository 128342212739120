import React, { Component } from "react";
import "./FrontPage.scss";

import ProfilePicture from "../../components/ProfilePicture";
import content from "../../content";

class FrontPage extends Component {
  render() {
    return (
      <div className="front-page">
        {Object.keys(content).map((key) => {
          const person = content[key];
          return (
            <ProfilePicture
              link={`/${key}`}
              title={person.name}
              src={person.profilePicture}
              styles={person.profilePictureStyles}
              key={key}
            />
          );
        })}
      </div>
    );
  }
}

export default FrontPage;
