import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import Breadcrumbs from "../../components/Breadcrumbs";
import Section from "../../components/Section";
import Signature from "../../components/Signature";
import content, { ContentType } from "../../content";
import "./PersonPage.scss";

class PersonPage extends Component {
  constructor(props) {
    super(props);

    this.name = this.props.match.params.name;

    if (!!content[this.name]) {
      this.person = content[this.name];
    } else {
      // Invalid name. Navigate to front page
      this.props.history.push("/");
    }
  }

  renderContent = () => {
    return (
      <>
        {this.person.content.map((c, i) => {
          switch (c.type) {
            case ContentType.LIST:
              return this.renderList(c.value, i);
            case ContentType.TEXT:
              return this.renderText(c.value, i);
            default:
              break;
          }
          return null;
        })}
      </>
    );
  };

  renderText = (value, textKey) => {
    return (
      <Section isNarrow key={textKey}>
        {value.map((p, i) => (
          <p className="person-page__paragraph" key={i}>
            {p}
          </p>
        ))}
      </Section>
    );
  };

  renderList = (value, listKey) => {
    return (
      <Section isNarrow key={listKey}>
        <ul className="person-page__ul">
          {value.map((p, i) => (
            <li className="person-page__li" key={i}>
              {p}
            </li>
          ))}
        </ul>
      </Section>
    );
  };

  renderGallery = () => {
    return (
      this.person.gallery &&
      this.person.gallery.length > 0 && (
        <Section isNarrow>
          <ImageGallery
            items={this.person.gallery.map((p) => ({
              thumbnail: p.original,
              ...p,
            }))}
            showBullets={true}
            showPlayButton={false}
            showThumbnails={false}
          />
        </Section>
      )
    );
  };

  render() {
    return this.person ? (
      <div className="person-page">
        <Breadcrumbs activePageName={this.person.name} />
        <Section>
          <>
            <div className="person-page__profile-picture">
              <img
                alt={this.person.name}
                title={this.person.name}
                src={this.person.profilePicture}
              />
            </div>

            <h1 className="person-page__title">{this.person.name}</h1>
            {this.renderContent()}
            <Signature>{this.person.name}</Signature>
            {this.renderGallery()}
          </>
        </Section>
      </div>
    ) : null;
  }
}

export default PersonPage;
